<template>
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <router-link to="/">Home</router-link>
      </li>
      <li class="breadcrumb-item active" aria-current="page">Support</li>
      <li class="breadcrumb-item active" aria-current="page">Open ticket</li>
    </ol>
  </nav>
  <section class="section-default">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-xl-10">
          <div class="card">
            <div class="card-body">
              <h5 class="fw-bold text-black"> Create Ticket </h5>
              <form method="post">
                <div class="row g-3 mb-4">
                  <div class="col-md-12 input-box">
                    <label for="Subject" class="form-label">Subject</label>
                    <input type="text" class="form-control eurondoes-form-control" id="Subject" placeholder="Subject" required>
                  </div>

                  <div class="col-12 input-box">
                    <label for="Message" class="form-label">Message</label>
                    <textarea type="text" class="form-control eurondoes-form-control" id="Message" rows="5" placeholder="Your message..." required></textarea>
                  </div>
                  <div class="col-md-8 col-sm-12">
                    <label class="form-label">Attachments</label>
                    <div v-for="(file, index) in files" :key="index">
                      <label :for="'formFile' + index" class="form-label form-file-label">
                        <div class="btn btn-primary">Choose file</div>
                        <span v-html="file.message"></span>
                      </label>
                      <input class="form-control" type="file" :id="'formFile' + index" @change="previewFile(index, $event)">
                    </div>
                  </div>
                  <div class="col-md-4 col-sm-12">
                    <div class="btn btn-primary add-more-files" @click="addFileField">
                      <i class="fa-solid fa-plus" /> Add more
                    </div>
                  </div>
                </div>
                <button class="w-25 btn btn-primary" type="submit">Submit</button>
                <button class="w-25 btn btn-secondary ms-3" type="submit">Cancel</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
    import { ref } from 'vue';

    const message = ref('.jpg, .gif, .jpeg, .png, .txt, .pdf');
    const files = ref([
        { message: message.value }
    ]);

    function previewFile(index, event) {
        const file = event.target.files[0];
        files.value[index].message = file.name;
        console.log(files);
    }
    function addFileField() {
        files.value.push({ message: message.value });
    }
</script>

<style scoped>
  .input-box {
    top: 0;
    left: 0;
    display: inline-block;
    vertical-align: top;
    position: relative;
    width: 100%;
    font-weight: 400;
  }
  .form-control.eurondoes-form-control {
    position: relative;
    vertical-align: top;
    border: 1px solid #ddd;
    display: -moz-inline-stack;
    display: inline-block;
    color: #626262;
    outline: 0;
    background-color: #fff;
    box-shadow: none;
    border-radius: 0;
    block-size: 45px;
    font-size: 14px;
    padding: 6px 12px;
  }
  textarea.form-control.eurondoes-form-control {
    block-size: inherit;
  }
  [id^='formFile'] {
    display: none;
  }
  .form-file-label {
    padding: 4px 4px;
    border: 1px solid #edeff1;
    border-radius: 4px;
    display: block;
    background-color: #fff9f9;
  }
  .form-file-label span {
    color: #999;
    padding-left: 20px;
    vertical-align: -2px;
  }
  .add-more-files {
    margin-top: 38px;
  }
</style>