<template>
  <header class="d-flex align-items-center justify-content-md-between sticky-top py-2 mb-3 bg-white shadow">
    <div class="col-md-3 mb-2 mb-md-0">
      <a href="/" class="navbar-brand px-3">
        <img src="../../public/logo.svg" alt="Euronodes logo">
      </a>
    </div>
    <div class="col-md-3 text-end px-3">
      <span class="me-3">Hi, Adam</span>
      <button type="button" class="btn btn-primary" @click="$emit('user-logged-out')">
        <i class="fa-solid fa-arrow-right-from-bracket" /> Logout
      </button>
    </div>
  </header>
</template>