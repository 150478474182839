<template>
  <div class="d-flex align-items-center py-4 bg-body-tertiary min-vh-100"  v-if="!authToken">
    <div class="form-signin w-100 m-auto">
      <form>
        <img class="mb-4 mx-auto d-block" src="../public/logo.svg" alt="Euronodes logo" height="40">
        <h1 class="h4 mb-3 fw-normal text-center">Please sign in</h1>
        <div class="form-floating">
          <input type="email" class="form-control" id="floatingInput" placeholder="Email"
                 v-model="email">
          <label for="floatingInput">Email address</label>
        </div>
        <div class="form-floating">
          <input type="password" class="form-control" id="floatingPassword" placeholder="Password"
                 v-model="password">
          <label for="floatingPassword">Password</label>
        </div>
        <div class="alert alert-danger login-error" role="alert" :class="{ 'd-none': !errorVisible }">
          The <strong>email address</strong> or <strong>password</strong> is incorrect
        </div>
        <div class="form-check text-start my-3">
          <input class="form-check-input" type="checkbox" value="remember-me" id="flexCheckDefault">
          <label class="form-check-label" for="flexCheckDefault">
            Remember me
          </label>
        </div>
        <div class="btn btn-primary w-100 py-2" @click="userAccess">Sign in</div>
      </form>
    </div>
  </div>

  <Header v-if="authToken" @user-logged-out="handleLogout"/>
  <main class="container-fluid" v-if="authToken">
    <div class="row">
      <div class="sidebar border border-right col-md-3 col-lg-2 p-0 h-100">
        <LeftSidebar/>
      </div>
      <div class="col-md-9 ms-sm-auto col-lg-10 px-md-4">
        <router-view></router-view>
      </div>
    </div>
  </main>
  <Footer v-if="authToken"/>
</template>

<script setup>
    import Header from '@/components/AppHeader.vue'
    import Footer from '@/components/AppFooter.vue'
    import LeftSidebar from '@/components/LeftSidebar.vue';
    import { ref } from 'vue';
    import axios from 'axios';

    let email = '';
    let password = '';
    let authToken = ref(null);
    let errorVisible = ref(false);

    function userAccess() {
        axios.post('https://spublic.gnuhost.tech/rpc/login', {
            "email": email,
            "pass": password
        })
            .then(response => {
                const data = response.data;
                authToken.value = data.token;
                localStorage.setItem('authToken', authToken.value);
                errorVisible.value = false;
            })
            .catch(() => {
                errorVisible.value = true;
            });
    }

    if (localStorage.getItem('authToken')) {
        authToken.value = localStorage.getItem('authToken');
    }

    function handleLogout() {
        localStorage.removeItem('authToken');
        authToken.value = null;
    }
</script>

<style scoped>
  .form-signin {
    max-width: 330px;
    padding: 1rem;
  }

  .form-signin .form-floating:focus-within {
    z-index: 2;
  }

  .form-signin input[type="email"] {
    margin-bottom: -1px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  .form-signin input[type="password"] {
    margin-bottom: 10px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  .login-error {
    font-size: 14px;
    padding: 8px 10px;
  }
</style>