import { createApp } from 'vue'
import App from './App';
import router from '@/router/router';

// Bootstrap
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.bundle.min.js'
import 'bootstrap/dist/js/bootstrap.min.js'

// Styles
import './assets/css/style.css';

const app = createApp(App)

app
    .use(router)
    .mount('#app')
