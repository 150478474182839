<template>
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <router-link to="/">Home</router-link>
      </li>
      <li class="breadcrumb-item active" aria-current="page">Account Details</li>
      <li class="breadcrumb-item active" aria-current="page">User Data</li>
    </ol>
  </nav>
  <section class="section-default">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-xl-10">
          <div class="card">
            <div class="card-body">
              <h5 class="fw-bold text-black"> Personal Information </h5>
              <div class="row">
                <div class="col-sm-6">
                  <div class="mb-3 input-box">
                    <label for="first_name" class="field-icon"><i class="fa-solid fa-user"></i></label>
                    <input type="text" class="form-control eurondoes-form-control" id="first_name" placeholder="First Name" v-model="user_data.first_name">
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="mb-3 input-box">
                    <label for="last_name" class="field-icon"><i class="fa-solid fa-user"></i></label>
                    <input type="text" class="form-control eurondoes-form-control" id="last_name" placeholder="Last Name" v-model="user_data.last_name">
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-6">
                  <div class="mb-3 input-box">
                    <label for="email" class="field-icon"><i class="fa-solid fa-envelope"></i></label>
                    <input type="email" class="form-control eurondoes-form-control" id="email" placeholder="Email Address" v-model="user_data.email">
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="mb-3 input-box">
                    <label for="phone_number" class="field-icon"><i class="fa-solid fa-user"></i></label>
                    <input type="text" class="form-control eurondoes-form-control" id="phone_number" placeholder="Phone Number">
                  </div>
                </div>
              </div>
              <h5 class="fw-bold text-black"> Billing Address </h5>
              <div class="mb-3 input-box">
                <label for="company_name" class="field-icon"><i class="fa-solid fa-building"></i></label>
                <input type="text" class="form-control eurondoes-form-control" id="company_name" placeholder="Company Name (Optional)">
              </div>
              <div class="row">
                <div class="col-sm-6">
                  <div class="mb-3 input-box">
                    <label for="street_address" class="field-icon"><i class="fa-solid fa-location-dot"></i></label>
                    <input type="text" class="form-control eurondoes-form-control" id="street_address" placeholder="Street Address">
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="mb-3 input-box">
                    <label for="street_address_2" class="field-icon"><i class="fa-solid fa-location-dot"></i></label>
                    <input type="text" class="form-control eurondoes-form-control" id="street_address_2" placeholder="Street Address 2">
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-4">
                  <div class="mb-3 input-box">
                    <label for="city" class="field-icon"><i class="fa-solid fa-city"></i></label>
                    <input type="text" class="form-control eurondoes-form-control" id="city" placeholder="City" v-model="user_data.city">
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="mb-3 input-box">
                    <label for="state" class="field-icon"><i class="fa-solid fa-signs-post"></i></label>
                    <input type="text" class="form-control eurondoes-form-control" id="state" placeholder="State">
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="mb-3 input-box">
                    <label for="postcode" class="field-icon"><i class="fa-solid fa-envelopes-bulk"></i></label>
                    <input type="text" class="form-control eurondoes-form-control" id="postcode" placeholder="Postcode">
                  </div>
                </div>
              </div>
              <div class="mb-3 input-box">
                <label for="vat_number" class="field-icon"><i class="fa-solid fa-building"></i></label>
                <input type="text" class="form-control eurondoes-form-control" id="vat_number" placeholder="VAT Number (Optional)">
              </div>
              <h5 class="fw-bold text-black"> Account Security </h5>
              <div class="row">
                <div class="col-sm-6">
                  <div class="mb-3 input-box">
                    <label for="password" class="field-icon"><i class="fa-solid fa-lock"></i></label>
                    <input type="password" class="form-control eurondoes-form-control" id="password" placeholder="Password">
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="mb-3 input-box">
                    <label for="confirm_password" class="field-icon"><i class="fa-solid fa-lock"></i></label>
                    <input type="password" class="form-control eurondoes-form-control" id="confirm_password" placeholder="Confirm Password">
                  </div>
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-center mt-2">
              <button class="btn btn-primary fw-bold" @click="saveUserData()"> Save<i class="fa-solid fa-check ms-2"></i></button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
    import {ref} from 'vue';

    const user_data = ref({username: 'adam', email: 'adam@gnuhost.tech', first_name: 'Adam', last_name: 'Smith', country: 'USA', street: 'Somewhere street', zip_code: '000-000', city: 'New York'});

    function saveUserData() {
        localStorage.setItem('user_data', JSON.stringify(user_data.value));
    }

    const storedUserData = localStorage.getItem('user_data');
    if (storedUserData) {
        user_data.value = JSON.parse(storedUserData);
    }
</script>

<style scoped>
  .input-box {
    top: 0;
    left: 0;
    display: inline-block;
    vertical-align: top;
    position: relative;
    width: 100%;
    font-weight: 400;
  }
  .field-icon {
    top: 0;
    z-index: 4;
    width: 42px;
    color: inherit;
    line-height: 36px;
    position: absolute;
    text-align: center;
    -webkit-transition: all .5s ease-out;
    -moz-transition: all .5s ease-out;
    -ms-transition: all .5s ease-out;
    -o-transition: all .5s ease-out;
    transition: all .5s ease-out;
    pointer-events: none;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .field-icon i {
    color: #bbb;
  }
  .form-control.eurondoes-form-control {
    position: relative;
    vertical-align: top;
    border: 1px solid #ddd;
    display: -moz-inline-stack;
    display: inline-block;
    color: #626262;
    outline: 0;
    background-color: #fff;
    box-shadow: none;
    border-radius: 0;
    block-size: 45px;
    font-size: 14px;
    padding: 6px 12px 6px 36px;
  }
</style>