<template>
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <router-link to="/">Home</router-link>
      </li>
      <li class="breadcrumb-item active" aria-current="page">Services</li>
      <li class="breadcrumb-item active" aria-current="page">Assign IPs</li>
    </ol>
  </nav>
  <section class="section-default">
    <div class="container">
      <div class="row justify-content-center">
        <div class="card">
          <div class="card-body">
            <div class="row mb-3">
              <div class="col-md-6 col-sm-12">
                <input v-model="search" type="text" id="searchInput" placeholder="Search" class="form-control">
              </div>
            </div>
            <table class="table">
              <thead>
              <tr>
                <th scope="col">ID</th>
                <th scope="col">Server Title</th>
                <th scope="col">Server Name</th>
                <th scope="col">Assigned IP's</th>
                <th scope="col">Actions</th>
              </tr>
              </thead>
              <tbody style="text-align:left;">
              <tr v-for="(server,index) in filteredList" :key="index">
                <td>{{server.id}}</td>
                <td>{{server.title}}</td>
                <td><input type="text" v-model="server.name"></td>
                <td>
                  <ul class="list-unstyled">
                    <li v-for="ip in server.assigned_ips" :key="ip">{{ ip }}</li>
                  </ul>
                </td>
                <td>
                  <button class="btn btn-primary btn-sm">Additonal IP (3.50€)</button>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
    import { ref, onMounted, watch, watchEffect } from 'vue';
    import { Tooltip } from 'bootstrap'

    const servers = ref([
        {id: '1', title: 'HP ProLiant DL360p Gen8 V1 (4xLFF) Rack Storage Server', name: 'My server name 1', assigned_ips: ['192.0.2.1', '192.0.2.2', '192.0.2.3']},
        {id: '2', title: 'HPE ProLiant DL380 Gen9 V4 (12xLFF+2xSFF) Rack Storage Server', name: 'My server name 2', assigned_ips: ['192.0.2.1', '192.0.2.2', '192.0.2.3']},
        {id: '3', title: 'HPE ProLiant DL360 Gen10 V2 Rack Server', name: 'My server name 3', assigned_ips: ['192.0.2.1', '192.0.2.2', '192.0.2.3']},
        {id: '4', title: 'HP ProLiant DL360p Gen8 V1 (4xLFF) Rack Storage Server', name: 'My server name 4', assigned_ips: ['192.0.2.1', '192.0.2.2', '192.0.2.3']},
    ]);

    const search = ref('');
    const filteredList = ref([]);

    // Search function
    watchEffect(() => {
        const searchTerm = search.value.toLowerCase();
        filteredList.value = servers.value.filter(item => {
            return (
                item.id.toLowerCase().includes(searchTerm) ||
                item.title.toLowerCase().includes(searchTerm) ||
                item.name.toLowerCase().includes(searchTerm) ||
                item.assigned_ips.join(', ').toLowerCase().includes(searchTerm)
            );
        });
    });

    // Local storage
    const saveToLocalStorage = () => {
        localStorage.setItem('servers', JSON.stringify(servers.value));
    };
    const loadFromLocalStorage = () => {
        const localServers = localStorage.getItem('servers');
        if (localServers) {
            servers.value = JSON.parse(localServers);
        }
    };
    onMounted(() => {
        loadFromLocalStorage();
    });
    watch(servers, saveToLocalStorage, { deep: true });

    new Tooltip(document.body, {
        selector: "[data-bs-toggle='tooltip']",
    })
</script>

<style scoped>
  .table > :not(caption) > * > * {
    padding: 2px;
    text-align: center;
    vertical-align: middle;
  }
  table input {
    border: none;
    text-align: center;
  }
  table ul {
    margin-bottom: 0;
  }
</style>