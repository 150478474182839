<template>
  <div>
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link to="/">Home</router-link>
        </li>
        <li class="breadcrumb-item">
          <router-link to="/services">Services</router-link>
        </li>
        <li class="breadcrumb-item">
          <router-link to="/services">My Services</router-link>
        </li>
        <li class="breadcrumb-item active" aria-current="page">Service Details</li>
      </ol>
    </nav>
    <div class="py-2 mt-5 mb-3">
      <h2>{{ serviceDetails.title }}</h2>
      <p class="lead">Dedicated Servers</p>
      <p class="fw-bold">Status:
        <span :class="{ 'text-success': serviceDetails.status === 'Active', 'text-danger': serviceDetails.status !== 'Active', }">
          {{ serviceDetails.status }}
        </span>
      </p>
      <a href="#" class="fw-bold text-danger">Request Cancellation</a>
    </div>
    <div class="row">
      <div class="col-md-3 col-sm-12">
        <div class="card">
          <div class="card-header text-uppercase">
            <h5 class="card-title mb-0">Payment information</h5>
          </div>
          <div class="card-body">
            <ul class="list-unstyled mb-0">
              <li class="py-1"><strong>Registration date:</strong> {{ serviceDetails.reg_date }}</li>
              <li class="py-1"><strong>Next due date:</strong> {{ serviceDetails.due_date }}</li>
              <li class="py-1"><strong>Recuring Ammount:</strong> €{{ serviceDetails.price }}EUR</li>
              <li class="py-1"><strong>Billing Cycle:</strong> {{ serviceDetails.billing_cycle }}</li>
              <li class="py-1"><strong>Payment Method:</strong> {{ serviceDetails.payment_method }}</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="col-md-4 col-sm-12">
        <div class="card">
          <div class="card-header text-uppercase">
            <h5 class="card-title mb-0">Server information</h5>
          </div>
          <div class="card-body">
            <ul class="list-unstyled mb-0">
              <li class="fw-bold py-1">Status:
                <span :class="{ 'text-success': serviceDetails.status === 'Active', 'text-danger': serviceDetails.status !== 'Active', }">
                  {{ serviceDetails.status }}
                </span>
              </li>
              <li class="py-1"><strong>Hostname:</strong> {{ serviceDetails.hostname }}</li>
              <li class="py-1"><strong>Primary IP:</strong> {{ serviceDetails.ip }}</li>
              <li class="py-1">
                <div class="row">
                  <div class="col-4 pe-0">
                    <strong>Nameservers:</strong>
                  </div>
                  <div class="col-8 p-0">
                    <span v-for="(ns, id) in serviceDetails.nameservers" :key="id">
                      {{ ns }}<br>
                    </span>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="col-md-5 col-sm-12">
        <div class="card">
          <div class="card-header text-uppercase">
            <h5 class="card-title mb-0">Configurable options</h5>
          </div>
          <div class="card-body">
            <ul class="list-unstyled mb-0">
              <li class="py-1" v-for="(option, id) in configurable_options" :key="id">
                <div class="row">
                  <div class="col-4 pt-1">
                    <strong>{{ option.title }}:</strong>
                  </div>
                  <div class="col-5 pt-1">
                    {{ option.price }}
                  </div>
                  <div class="col-3">
                    <button class="btn btn-primary btn-sm w-100" @click="toggleOptionStatus(option)">
                      {{ option.status === 'Enabled' ? 'Remove' : 'Add' }}
                    </button>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
    import { ref, onMounted, watch } from 'vue';

    const serviceDetails = ref({});

    onMounted(() => {
        const selectedService = localStorage.getItem('selectedService');
        if (selectedService) {
            serviceDetails.value = JSON.parse(selectedService);
        }
    });

    const configurable_options = ref([
        {id: '1', title: 'Additional IP', price: '1xOptions €3.50EUR', status: 'Enabled'},
        {id: '2', title: 'Additional IP x2', price: '2xOptions €7EUR', status: 'Disabled'},
        {id: '3', title: 'Additional IP x3', price: '3xOptions €10.50EUR', status: 'Disabled'},
    ]);

    function toggleOptionStatus(option) {
        option.status = option.status === 'Enabled' ? 'Disabled' : 'Enabled';
        localStorage.setItem('configurable_options', JSON.stringify(configurable_options.value));
    }

    const storedOptions = localStorage.getItem('configurable_options');
    if (storedOptions) {
        configurable_options.value = JSON.parse(storedOptions);
    }

    watch(configurable_options, (newOptions) => {
        localStorage.setItem('configurable_options', JSON.stringify(newOptions));
    });
</script>

<style scoped>
  .card-body {
    height: 192px;
  }
</style>