import {createRouter, createWebHistory} from 'vue-router';
import MainPage from "@/pages/MainPage";
import UserDataPage from "@/pages/UserDataPage";
import ServicesPage from "@/pages/ServicesPage";
import ServiceDetailsPage from "@/pages/ServiceDetailsPage";
import AssignIps from "@/pages/AssignIpsPage";
import OpenTicketPage from "@/pages/OpenTicketPage"


const routes = [
    {
        path: '/',
        component: MainPage
    },
    {
        path: '/user-data',
        component: UserDataPage
    },
    {
        path: '/services',
        component: ServicesPage
    },
    {
        path: '/services/:slug',
        name: 'service-details',
        component: ServiceDetailsPage,
        props: true,
    },
    {
        path: '/assign-ips',
        component: AssignIps
    },
    {
        path: '/open-ticket',
        component: OpenTicketPage
    },
]

const router = createRouter({
    routes,
    history: createWebHistory(process.env.BASE_URL)
})

export default router;