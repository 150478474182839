<template>
  <div class="offcanvas-md offcanvas-end bg-white" tabindex="-1" id="sidebarMenu" aria-labelledby="sidebarMenuLabel">
    <div class="offcanvas-header">
      <h5 class="offcanvas-title" id="sidebarMenuLabel">Company name</h5>
      <button type="button" class="btn-close" data-bs-dismiss="offcanvas" data-bs-target="#sidebarMenu" aria-label="Close"></button>
    </div>
    <div class="offcanvas-body d-md-flex flex-column px-3 pt-lg-3 overflow-y-auto">
      <div @click="$router.push('/')" class="d-flex align-items-center pb-3 mb-3 link-body-emphasis text-decoration-none border-bottom">
        <span class="fs-5 fw-semibold" role="button">Navigation</span>
      </div>
      <ul class="list-unstyled ps-0 position-relative">
        <li class="mb-1">
          <button class="btn btn-toggle d-inline-flex align-items-center rounded border-0 collapsed w-100"
                  data-bs-toggle="collapse" data-bs-target="#account-details-collapse" aria-expanded="true">
            <i class="fa-solid fa-user" /> Account details
          </button>
          <div class="collapse show" id="account-details-collapse">
            <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
              <li>
                <router-link to="/user-data" class="link-body-emphasis d-inline-flex text-decoration-none rounded">
                  <i class="fa-solid fa-arrow-right" /> User data
                </router-link>
              </li>
            </ul>
          </div>
        </li>
        <li class="mb-1">
          <button class="btn btn-toggle d-inline-flex align-items-center rounded border-0 collapsed w-100"
                  data-bs-toggle="collapse" data-bs-target="#services-collapse" aria-expanded="true">
            <i class="fa-solid fa-gears" /> Services
          </button>
          <div class="collapse show" id="services-collapse">
            <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
              <li>
                <router-link to="/services" class="link-body-emphasis d-inline-flex text-decoration-none rounded">
                  <i class="fa-solid fa-gears" /> My Services
                </router-link>
              </li>
              <li>
                <router-link to="/assign-ips" class="link-body-emphasis d-inline-flex text-decoration-none rounded">
                  <i class="fa-solid fa-server" /> Assign IPs
                </router-link>
              </li>
            </ul>
          </div>
        </li>
        <li class="mb-1">
          <button class="btn btn-toggle d-inline-flex align-items-center rounded border-0 collapsed w-100"
                  data-bs-toggle="collapse" data-bs-target="#support-collapse" aria-expanded="true">
            <i class="fa-solid fa-life-ring" /> Support
          </button>
          <div class="collapse show" id="support-collapse">
            <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
              <li>
                <router-link to="/open-ticket" class="link-body-emphasis d-inline-flex text-decoration-none rounded">
                  <i class="fa-solid fa-comments" /> Open Ticket
                </router-link>
              </li>
            </ul>
          </div>
        </li>
        <li class="mb-1">
          <button class="btn btn-toggle d-inline-flex align-items-center rounded border-0 collapsed w-100"
                  data-bs-toggle="collapse" data-bs-target="#billing-collapse" aria-expanded="true">
            <i class="fa-solid fa-building-columns" /> Billing
          </button>
          <div class="collapse show" id="billing-collapse">
            <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
              <li>
                <a href="https://xomotite.invoicing.co/client/key_login/8WOzG1N4ipmqJ7K5l38izlhAo2N8TcCP"
                   class="link-body-emphasis d-inline-flex text-decoration-none rounded" target="_blank" rel="nofollow">
                  <i class="fa-solid fa-credit-card" /> Invoices
                </a>
              </li>
            </ul>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup>

</script>

<style scoped>
  .btn-toggle::after {
    width: 1.25em;
    line-height: 0;
    content: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='rgba%280,0,0,.5%29' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M5 14l6-6-6-6'/%3e%3c/svg%3e");
    transition: transform .35s ease;
    transform-origin: .5em 50%;
    position: absolute;
    right: 0;
  }
  .btn-toggle[aria-expanded="true"]::after {
    transform: rotate(90deg);
  }
  .btn-toggle-nav a {
    padding: .1875rem .5rem;
    margin-top: .125rem;
    margin-left: 1.25rem;
  }
  .btn-toggle-nav a i,
  #sidebarMenu button i {
    margin-right: 5px;
    color: #d50c2d;
  }
  .collapse .btn-toggle-nav a i {
    width: 17px;
  }
  .btn-toggle-nav a i:before {
    position: relative;
    top: 3px;
  }
</style>