<template>
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <router-link to="/">Home</router-link>
      </li>
      <li class="breadcrumb-item active" aria-current="page">Services</li>
      <li class="breadcrumb-item active" aria-current="page">My Services</li>
    </ol>
  </nav>
  <section class="section-default">
    <div class="container">
      <div class="row justify-content-center">
        <div class="card">
          <div class="card-body">
            <div class="row mb-3">
              <div class="col-md-6 col-sm-12">
                <input v-model="search" type="text" id="searchInput" placeholder="Search" class="form-control">
              </div>
              <div class="text-end col-md-6 col-sm-12">
                <a href="#">How to reinstall server</a>
              </div>
            </div>
            <table class="table">
              <thead>
              <tr>
                <th scope="col">ID</th>
                <th scope="col">Prox</th>
                <th scope="col">Name</th>
                <th scope="col">IP</th>
                <th scope="col">Online</th>
                <th scope="col">Firewall</th>
                <th scope="col">VMs</th>
                <th scope="col">Office</th>
                <th scope="col">Location</th>
                <th scope="col">Vlan</th>
                <th scope="col">IPMI IP</th>
                <th scope="col">Due date</th>
                <th scope="col">Actions</th>
              </tr>
              </thead>
              <tbody style="text-align:left;">
              <tr v-for="(service,index) in filteredList" :key="index">
                <td>{{service.id}}</td>
                <td>{{service.prox}}</td>
                <td>
                  <input type="text" v-model="service.name">
                </td>
                <td>{{service.ip}}</td>
                <td>
                  <div :class="service.online === 'On' ? 'text-success' : 'text-danger'">
                    <i class="fa-solid fa-xs fa-circle"/>
                  </div>
                </td>
                <td>{{service.firewall}}</td>
                <td>{{service.vms}}</td>
                <td>{{service.office}}</td>
                <td>
                  <img :src="require(`@/assets/img/flags/${service.location.symbol.toLowerCase()}.png`)"
                       :alt="service.location.country + ' flag'" width="24"
                       data-bs-toggle="tooltip" :data-bs-title="service.location.country"
                  >
                </td>
                <td>{{service.vlan}}</td>
                <td>{{service.ipmi_ip}}</td>
                <td>{{service.due_date}}</td>
                <td>
                  <button>
                    <i class="fa-solid fa-square text-danger"></i>
                  </button>
                  <button>
                    <i class="fa fa-play text-success"></i>
                  </button>
                  <button>
                    <i class="fa-solid fa-rotate fa-lg text-primary"></i>
                  </button>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
    import { ref, onMounted, watch, watchEffect } from 'vue';
    import { Tooltip } from 'bootstrap'

    const services = ref([
        {id: '1', title: 'HP ProLiant DL360p Gen8 V1 (4xLFF) Rack Storage Server', name: 'My service name 1', url: 'abc.euronodes.com', ip: '1.1.1.1', location: {symbol: 'DE', country: 'Germany'}, nameservers: ['ns1.euronodes.com', 'ns2.euronodes.com'], price: 24, reg_date: '2023-01-29', due_date: '2023-12-29', billing_cycle: 'Monthly', payment_method: 'Bank Transfer', online: 'On', slug: 'service-one', prox: '104', firewall: 'ON', vms: '20', office: 'EUSTH00', vlan: '0', ipmi_ip: '2.2.2.2'},
        {id: '2', title: 'HPE ProLiant DL380 Gen9 V4 (12xLFF+2xSFF) Rack Storage Server', name: 'My service name 2', url: 'asd.euronodes.com', ip: '2.1.1.1', location: {symbol: 'DE', country: 'Germany'}, nameservers: ['ns1.euronodes.com', 'ns2.euronodes.com'], price: 104, reg_date: '2023-01-29', due_date: '2023-12-27', billing_cycle: 'Monthly', payment_method: 'Bank Transfer', online: 'On', slug: 'service-two', prox: '104', firewall: 'ON', vms: '21', office: 'EUSTH01', vlan: '0', ipmi_ip: '2.2.2.2'},
        {id: '3', title: 'HPE ProLiant DL360 Gen10 V2 Rack Server', name: 'My service name 3', url: 'zxc.euronodes.com', ip: '3.1.1.1', location: {symbol: 'PT', country: 'Portugal'}, nameservers: ['ns1.euronodes.com', 'ns2.euronodes.com'], price: 439, reg_date: '2023-01-29', due_date: '2023-12-30', billing_cycle: 'Monthly', payment_method: 'Bank Transfer', online: 'On', slug: 'service-three', prox: '104', firewall: 'ON', vms: '22', office: 'EUSTH02', vlan: '0', ipmi_ip: '2.2.2.2'},
        {id: '4', title: 'HP ProLiant DL360p Gen8 V1 (4xLFF) Rack Storage Server', name: 'My service name 4', url: 'abc.euronodes.com', ip: '4.1.1.1', location: {symbol: 'PT', country: 'Portugal'}, nameservers: ['ns1.euronodes.com', 'ns2.euronodes.com'], price: 24, reg_date: '2023-01-29', due_date: '2023-12-28', billing_cycle: 'Monthly', payment_method: 'Bank Transfer', online: 'Off', slug: 'service-four', prox: '104', firewall: 'ON', vms: '23', office: 'EUSTH03', vlan: '0', ipmi_ip: '2.2.2.2'},
    ]);

    const search = ref('');
    const filteredList = ref([]);

    // Search function
    watchEffect(() => {
        const searchTerm = search.value.toLowerCase();
        filteredList.value = services.value.filter(item => {
            return (
                item.id.toLowerCase().includes(searchTerm) ||
                item.prox.toLowerCase().includes(searchTerm) ||
                item.name.toLowerCase().includes(searchTerm) ||
                item.ip.toLowerCase().includes(searchTerm) ||
                item.vms.toLowerCase().includes(searchTerm) ||
                item.office.toLowerCase().includes(searchTerm) ||
                item.vlan.toLowerCase().includes(searchTerm) ||
                item.ipmi_ip.toLowerCase().includes(searchTerm) ||
                item.due_date.toLowerCase().includes(searchTerm)
            );
        });
    });

    // Local storage
    const saveToLocalStorage = () => {
        localStorage.setItem('services', JSON.stringify(services.value));
    };
    const loadFromLocalStorage = () => {
        const localServices = localStorage.getItem('services');
        if (localServices) {
            services.value = JSON.parse(localServices);
        }
    };
    onMounted(() => {
        loadFromLocalStorage();
    });
    watch(services, saveToLocalStorage, { deep: true });

    new Tooltip(document.body, {
        selector: "[data-bs-toggle='tooltip']",
    })
    // OLD

    // let service = {};
    // function selectService(id, services) {
    //     const foundService = services.find((s) => s.id === id);
    //     if (foundService) {
    //         service = foundService;
    //         navigateToServiceDetails(service);
    //     }
    // }
    // const router = useRouter();
    // const navigateToServiceDetails = (service) => {
    //     localStorage.setItem('selectedService', JSON.stringify(service));
    //     router.push({ name: 'service-details', params: { slug: service.slug } });
    // };
</script>

<style scoped>
  .table > :not(caption) > * > * {
    padding: 2px;
    text-align: center;
  }
  table input {
    border: none;
    text-align: center;
  }
  tbody button {
    font-size: 10px;
    width: 14px;
    border: none;
    margin: 0 2px;
    padding: 0;
    background-color: transparent;
  }
  tbody button i:hover {
    filter: brightness(150%);
  }
</style>